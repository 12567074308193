import styled from 'styled-components';

export const Container = styled('div')(() => ({
  height: '100%',
  padding: '15px',
  backgroundColor: 'white',
  maxWidth: '700px',
  margin: '0 auto',
  width: '100%',
}));

export const DownloadButton = styled('a')(() => ({}));

export const DownloadButtonContent = styled('a')(() => ({
  padding: '10px 10px',
  margin: '20px 0',
  backgroundColor: 'red',
  width: '100%',
  fontSize: '24px',
}));
