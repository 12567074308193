import * as React from 'react';
import { useCallback } from 'react';
import { CardContainer, CardHeaderStyled, CardStyled } from './styles';
import { Widget } from '../../../pages/UserGroupsPage';

interface Props {
  widget: Widget;
  setSelectedWidget: any;
}

const WidgetCard: React.FC<Props> = ({ widget, setSelectedWidget }) => {
  const click = useCallback(() => {
    const widgetCopy: Widget = {
      widgetName: widget.widgetName,
      widgetUrlImage: widget.widgetUrlImage,
      description: widget.description,
      widgetUrl: widget.widgetUrl,
      type: widget.type,
      downloadItems: widget.downloadItems,
      internalWidgetType: widget.internalWidgetType,
    };
    setSelectedWidget(widgetCopy);
  }, [setSelectedWidget, widget]);

  return (
    <>
      <CardContainer onClick={click}>
        <CardStyled>
          <CardHeaderStyled title={widget?.widgetName} />
        </CardStyled>
      </CardContainer>
    </>
  );
};

export default WidgetCard;
