import * as React from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useCallback, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Tooltip } from '@mui/material';

const ListItem = styled('li')(() => ({
  margin: '3px',
}));

interface AlertProps {
  open: boolean;
  message: string;
}

interface props {
  downloadItemsList: DownloadItem[];
  setDownloadItemList: any;
  downloadItemsUrlToDelete: string[];
  setDownloadItemsUrlToDelete: any;
  isEdit: boolean;
}

export interface DownloadItem {
  description: string;
  url: string;
}

export const AddUrlDownloads: React.FC<props> = ({
  downloadItemsList,
  setDownloadItemList,
  downloadItemsUrlToDelete,
  setDownloadItemsUrlToDelete,
  isEdit,
}) => {
  // used only to set focus after adding a DownloadItem
  const descriptionInputRef = React.useRef<HTMLInputElement>(null);

  const [description, setDescription] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [required, setRequired] = useState<any>({});
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
    message: '',
  });

  const handleDeleteChip = (downloadItem: DownloadItem): void => {
    if (isEdit) {
      setDownloadItemsUrlToDelete([
        ...downloadItemsUrlToDelete,
        downloadItem.url,
      ]);
    }

    const index = downloadItemsList.indexOf(downloadItem);
    downloadItemsList.splice(index, 1);
    setDownloadItemList([...downloadItemsList]);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ): any => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertProps({
      open: false,
      message: '',
    });
  };

  const InvalidateURL = (value: string): boolean => {
    const urlPattern =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/;
    return !urlPattern.test(value);
  };

  const hasRequiredFields = useCallback(
    (downloadItemForm: DownloadItem): boolean => {
      const requiredObj: any = {};
      if (downloadItemForm.description === '') {
        requiredObj.description = true;
      }

      if (downloadItemForm.url === '') {
        requiredObj.url = true;
      }

      if (Object.keys(requiredObj).length > 0) {
        setRequired(requiredObj);
        return true;
      }
      return false;
    },
    [],
  );

  const handleAddDownloadItem = useCallback(() => {
    const downloadItemForm: DownloadItem = {
      description,
      url: encodeURI(url),
    };

    if (!hasRequiredFields(downloadItemForm)) {
      if (InvalidateURL(downloadItemForm.url)) {
        setAlertProps({
          open: true,
          message: 'url: invalid format',
        });
        return;
      }

      const downloadItem = downloadItemsList.find(
        (u: DownloadItem) => u.url === downloadItemForm.url,
      );
      if (!downloadItem) {
        setDownloadItemList([...downloadItemsList, downloadItemForm]);
      } else {
        setAlertProps({
          open: true,
          message: `Provided URL already exists (${downloadItem.description})`,
        });
      }

      setDescription('');
      setUrl('');
      setRequired({});
    }
  }, [
    hasRequiredFields,
    setDownloadItemList,
    url,
    description,
    downloadItemsList,
  ]);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: '100%',
          alignSelf: 'center',
          height: 'auto',
          paddingBottom: '10px',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ width: '94%', alignSelf: 'center' }}>
            <h3
              style={{
                margin: 'unset',
                marginTop: '12px',
                marginBottom: '5px',
                paddingLeft: '3px',
              }}
            >
              Add Download
            </h3>
          </div>

          <TextField
            margin="dense"
            id="description"
            name="description"
            value={description}
            onChange={event => setDescription(event.target.value)}
            label="Descrption"
            type="text"
            variant="outlined"
            error={!!required.description}
            helperText={required.description ? 'required' : ''}
            style={{ width: '94%', alignSelf: 'center' }}
            onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
            inputRef={descriptionInputRef}
          />
          <TextField
            margin="dense"
            id="url"
            name="url"
            value={url}
            onChange={event => setUrl(event.target.value)}
            label="URL"
            type="text"
            variant="outlined"
            error={!!required.url}
            helperText={required.url ? 'required' : ''}
            style={{ width: '94%', alignSelf: 'center' }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddDownloadItem();
                if (descriptionInputRef != null) {
                  descriptionInputRef?.current?.focus();
                }
              }
            }}
          />
          <div
            style={{
              width: '94%',
              alignSelf: 'center',
              paddingTop: '7px',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button onClick={handleAddDownloadItem} variant="contained">
              Add download
            </Button>
          </div>
        </Box>
        {downloadItemsList.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
            }}
          >
            <Paper
              sx={{
                background: 'aliceblue',
                display: 'flex',
                justifyContent: 'start',
                flexWrap: 'wrap',
                listStyle: 'none',
                width: '94%',
                p: 0.5,
                m: 0.5,
              }}
              elevation={4}
            >
              {downloadItemsList.map(
                (downloadItem: DownloadItem, index: number) => {
                  return (
                    <ListItem key={index}>
                      <Tooltip
                        arrow
                        title={`${downloadItem.url}`}
                        placement="top-start"
                      >
                        <Chip
                          label={`${downloadItem.description}`}
                          size="small"
                          onDelete={() => handleDeleteChip(downloadItem)}
                        />
                      </Tooltip>
                    </ListItem>
                  );
                },
              )}
            </Paper>
          </div>
        )}
      </Paper>
      <Snackbar
        open={alertProps.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {alertProps.message}
        </Alert>
      </Snackbar>
    </>
  );
};
