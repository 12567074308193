import React, { useEffect } from 'react';

import { Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Container } from './styles';
import { useAuth } from '../../context/authContext';
import { DownloadItem } from '../../components/addUrlDownloads';
import { Widget } from '../UserGroupsPage';

const DownloadFilesPage: React.FC = () => {
  // state to store files
  const [downloadItems, setDownloadItems] = React.useState<DownloadItem[]>([]);

  // Função para baixar todos os arquivos
  const handleDownloadAll = (): void => {
    downloadItems.forEach(file => {
      const link = document.createElement('a');
      link.href = file.url;
      link.target = '_blank';
      link.download = file.description;
      setTimeout(() => {
        console.log('clicking', link);
        link.click();
      }, 210);
    });
  };

  // TODO usar essa lib pra gerar o icone dinamicamente, depedendo do formato do arquivo
  // https://github.com/corygibbons/react-file-icon

  // procurar como alterar no backend: o arquivo deve ter esse cabecalho para ser feito download
  // ao inves de abrir no navegador: (funcao get all)
  // Content-Disposition: attachment; filename="file name.jpg"
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition#syntax

  const { user, getUserGroups } = useAuth();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchDownloadItems = async (): Promise<void> => {
      const userInfo = await getUserGroups(user);
      const userGroupDownloadItems = userInfo?.widgets?.find(
        (x: Widget) => x.widgetUrl === '/downloads',
      )?.downloadItems;
      setDownloadItems(userGroupDownloadItems ?? []);
      setIsLoading(false);
    };

    fetchDownloadItems();
  }, [user, getUserGroups]);

  return (
    <Container>
      <h1>Downloads</h1>

      {isLoading && <p>Loading...</p>}
      {!isLoading && downloadItems.length === 0 && <p>No files to download</p>}

      <div>
        <div>
          {downloadItems.map((file, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <Button
                startIcon={<PictureAsPdfIcon />}
                variant="outlined"
                size="large"
                style={{
                  justifyContent: 'left',
                  width: '100%',
                  maxWidth: '700px',
                  textTransform: 'none',
                }}
                href={file.url}
                target="_blank"
              >
                {file.description}
              </Button>
            </div>
          ))}
        </div>

        {downloadItems.length > 1 && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="medium"
              onClick={handleDownloadAll}
              style={{
                textTransform: 'none',
              }}
            >
              Download All
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default DownloadFilesPage;
