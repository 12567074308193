export type InternalWidgetTypes = 'DASHBOARDS' | 'TODO_LIST' | 'DOWNLOADS';

export const fixedWidgets = [
  {
    widgetName: 'Dashboards',
    widgetUrl: '/dashboards',
    type: 'internal',
    internalWidgetType: 'DASHBOARDS' as InternalWidgetTypes,
  },
  {
    widgetName: 'TODO List',
    widgetUrl: '/tasks',
    type: 'internal',
    internalWidgetType: 'TODO_LIST' as InternalWidgetTypes,
  },
  {
    widgetName: 'Downloads',
    widgetUrl: '/downloads',
    type: 'internal',
    internalWidgetType: 'DOWNLOADS' as InternalWidgetTypes,
  },
];
